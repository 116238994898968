import React, { useContext } from "react";
import AuthProvider from "../context/AuthContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import BetSlipContext from "../context/BetSlipContext";
const GameCardNew = ({ res, type, message, game }) => {
  let { user } = useContext(AuthProvider);
  let { dolaunchCasinoNew, setMessage ,launchEGTCasino,
    launchCasino} = useContext(BetSlipContext);
  const navigate = useNavigate();
  return (
    <div
      className={`game-card position-relative ${res?.title ? "margin" : ""}`}
      onClick={() => {
        if (type == "sec") {
          if (!isEmpty(user)) {
            if (!isEmpty(res?.gameid)) {
              launchEGTCasino({
                platForm: res?.platForm,
                gameType: res?.gameType,
                casinoType: res?.casinoType,
                gameid: res?.gameid,
              });
            } else {
              if (
                res?.platForm !== "" &&
                res?.gameType !== "" &&
                res?.casinoType !== ""
              ) {
                if (!isEmpty(res?.table)) {
                  launchCasino({
                    platForm: res?.platForm,
                    gameType: res?.gameType,
                    casinoType: res?.casinoType,
                    table: res?.table,
                  });
                } else {
                  launchCasino({
                    platForm: res?.platForm,
                    gameType: res?.gameType,
                    casinoType: res?.casinoType,
                  });
                }
              }
            }
          } else {
            navigate("/login");
          }
        } else {
          if (!isEmpty(user)) {
            if (!isEmpty(message)) {
              setMessage({
                message: message,
                status: true,
                errorType: "P2P",
              });
            } else if (!isEmpty(res?.message)) {
              setMessage({
                message: res?.message,
                status: true,
                errorType: "P2P",
              });
            } else {
              dolaunchCasinoNew(
                game == "poker"
                  ? {
                      gameCode: res?.gameCode,
                      legacy: res?.legacy,
                      type: type,
                    }
                  : {
                      gameCode: res?.gameCode,
                      legacy: res?.legacy,
                      type: type,
                    }
              );
            }
            // newCasino({
            //   Id: res?.Id,
            //   ProductId: res?.ProductId,
            //   ProductName: res?.ProductName,
            //   ProductGameProviderId: res?.ProductGameProviderId,
            //   CategoryIds: res?.CategoryIds,
            //   ExternalId: res?.ExternalId,
            //   SubproviderId: res?.SubproviderId,
            // });
          } else {
            navigate("/login");
          }
        }
      }}
    >
      {res?.image || res?.img ? (
        <img
          style={
            res?.size == "size"
              ? { height: "120px" }
              : res?.size == "contain"
              ? { objectFit: "contain" }
              : {}
          }
          src={res?.image || res?.img}
          alt=""
          className="newCardImage"
        />
      ) : (
        <img
          src={"https://resources.craftbet.com/products/" + res?.MobileImageUrl}
          alt=""
        />
      )}
      {/* {res?.title && <p>{res?.title}</p>} */}
    </div>
  );
};

export default GameCardNew;
