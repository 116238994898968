import React, { useEffect, useState } from "react";
import LayoutComponent from "../../components/shared/Layout";
import HeadContainer from "./HeadContainer";
import CasinoFilter from "./CasinoFilter";
import GameCard from "../../components/GameCard";
import {
  gamesLiveArray,
  NewLiveCasino,
  popularArray,
  PokerGames,
  AviatorGames,
  EvolutionGames,
  VivoGames,
  BetGames,
  LiveGames,
  AisaGames,
  Aura,
  NavDepositWithrawData,
  NavDepositWithrawData2,
} from "../../constraints/constants";
import GameCardNew from "../../components/GameCardNew";
const Casino = () => {
  const [header, setHeader] = useState("poker");


  var settings1 = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    arrows: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <div>
      <LayoutComponent visibilityType={true}>

        <div className="payment-sec casino-category" {...settings1}>
          {NavDepositWithrawData2?.map((item) => {
            return (
              <div
                onClick={() => {
                  setHeader(item?.firstTab);
                }}
                className={`payment-box ${
                  header == item?.firstTab ? "active" : ""
                }`}
              >
                <figure>
                  <img src={item?.icons} alt="" />
                </figure>
                <span>{item?.title}</span>
              </div>
            );
          })}
        </div>
        <div
          className={
              "main-casino-wrapper"
          }
        >
          <div className="games-wrapper casino-all-games">
            {/* {header == "live" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {LiveGames?.map((res, index) => {
                    return <GameCardNew key={index} res={res} />;
                  })}
                </div>
              </div>
            )}
            {header == "ezugi" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {popularArray?.map((res, index) => {
                    return <GameCardNew key={index} res={res} />;
                  })}
                </div>
              </div>
            )} */}
            {header == "poker" && (
              <div className="games-card-inner bg-white">
                <img src={"../assets/images/poker/poker.webp"} />
                <div className="row">
                  {PokerGames?.map((res, index) => {
                    return (
                      <GameCardNew
                        key={index}
                        res={res}
                        type={"first"}
                        game={"poker"}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {/* {header == "aviator" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {AviatorGames?.map((res, index) => {
                    return (
                      <GameCardNew
                        key={index}
                        res={res}
                        type={"first"}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {header == "evolution" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {EvolutionGames?.map((res, index) => {
                    return <GameCardNew key={index} res={res} type={"sec"} />;
                  })}
                </div>
              </div>
            )}
            {header == "vivo" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {VivoGames?.map((res, index) => {
                    return <GameCardNew key={index} res={res} type={"sec"} />;
                  })}
                </div>
              </div>
            )}
            {header == "Asia" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {AisaGames?.map((res, index) => {
                    return <GameCardNew key={index} res={res} type={"sec"} />;
                  })}
                </div>
              </div>
            )} */}
            {header == "TABLE" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "TABLE";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "SLOT" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "SLOT";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "AWC" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "AWC";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "LIVE" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return [
                        "OTHERS",
                        "GAME-CARD",
                        "BACCARAT",
                        "ROULETTE",
                        "TRADITIONAL",
                        "DICE",
                        "GAME-SHOWS",
                      ].includes(res?.type);
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "FISHING" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "FISHING";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "EGAME" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "EGAME";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
                 {header == "aviator" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {AviatorGames?.map((res, index) => {
                    return <GameCardNew key={index} res={res} type={"first"} />;
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default Casino;
